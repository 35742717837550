// Fonts
$ho-font-path: '~@headtrip/theme-base/src/fonts/';
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

// Imports
@import '../../node_modules/@headtrip/theme-base/src/scss/hetzstrap';
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";

// Custom
main {
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
}
.ip-btn {
  font-size: 1.15rem;
}

.copy-container {
  background-color: $white;
  border-color: $white;
  color: $black;
}

.btn-copy {
  background-color: $gray-400!important;
  box-shadow: none;
  min-width: 83px;
  transition: width .5s ease-in-out, background-color .5s ease-in-out, color .5s ease-in-out;

  &.animated {
    background-color: $gray-600;
  }
  &:focus, &:active {
    box-shadow: none;
  }
}

@mixin ripples($color, $growthField) {
  position: relative;

  &:before {
    top: 50%;
    left: 50%;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 25px;
    animation: ripples .5s ease-in-out;
    transform: translateX(-50%) translateY(-50%);
  }
  @keyframes ripples {
    0% {
      border: 1px solid transparent;
    }
    100% {
      border: $growthField + px solid darken($color, 12%);
      opacity: 0;
      width: 140%;
    }
  }
}

.btn-ripple {
  @include ripples($gray-400, 50)
}

.text-code-orange {
  color: #F08D49;
}

.hidden {
  display:none!important;
}

// Media queries
@media (max-width: 767px) {
  .lead {
    font-size: 1rem;
  }
}
@media (max-width: 575px) {
  .v6-value {
    font-size: 0.75rem;
  }
}
